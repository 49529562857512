
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


const isProd = typeof window !== 'undefined' &&   window?.location.href.toLowerCase().includes('https://services.boeing.com') ? true : false;

if (isProd) {
  enableProdMode();
}
function setBaseUrl(){
  var elements = document.getElementsByTagName("a"); 
  for(var i=0; i<elements.length; i++){
    if(elements[i].href.split('/').includes('wbis-dev-1') && !elements[i].href.split('/').includes('myboeingfleet')){
      let newArr = elements[i].href.split('/');
      let index = newArr.indexOf('wbis-dev-1') + 1;
        newArr.splice(index , 0, 'myboeingfleet');
        elements[i].href = newArr.join('/');
  }
  }
 } 
 platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
}


 //document.addEventListener('DOMContentLoaded', bootstrap);