import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from "@spartacus/core";
import { SmartEditConfig, SmartEditRootModule, SMART_EDIT_FEATURE } from "@spartacus/smartedit/root";
import { ConfigService } from 'src/services/config.service';

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [SMART_EDIT_FEATURE]: {
        module: () =>
          import('@spartacus/smartedit').then((m) => m.SmartEditModule),
      },
    }
  }),
  {
    provide: SmartEditConfig,
    useFactory: (configService: ConfigService) => {
      return {
        smartEdit: {
          allowOrigin: configService.getConfig('hybSiteUrl'),
          storefrontPreviewRoute: 'cx-preview',
        },
      };
    },
    deps: [ConfigService],
  }
  ]
})
export class SmartEditFeatureModule { }
