// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  siteKey: 'myboeingfleet',
  prodSiteUrl: 'https://shop.boeing.com',
  hybSiteUrl: 'https://qa1.services.boeing.com',
  siteUrl: 'https://localhost:4200/',
  baseOCCUrl: 'https://qa1.services.boeing.com/',
  adobeLaunchUrl: '//assets.adobedtm.com/launch-ENeb77ca579d964dc6aae7d5bd7b538df2-staging.min.js',
  gtmId: 'GTM-MW7TJKZ',
  //Adobe tagging
  adobeCommonSDKUrl:'https://assets.adobedtm.com/981fe46f491f/bdf6dbd8451c/launch-a114652e2038-staging.min.js',
  // Adobe Analytics Common SDK 
  adobeCommonEnabled:true,
  adobeCommonPages:['cmsitem_00901001'],
  version:'',
  OhpCardView: true
};

// get the common library package number
getVersionNumber().then((version) => {
  environment.version = version;
  })
  
  // function to get the common library package number
   async function getVersionNumber(): Promise<string> {
    const data = await import('../../package.json');
   return data.dependencies['@bdhm/bgs-common-ui-lib'];
  }

  // mapper to get the base url of different envrionments
const urlMapper = new Map([
  ['stage','https://stage.services.boeing.com'],
  ['qa1','https://qa1.services.boeing.com'],
  ['qa2','https://qa2.services.boeing.com'],
  ['qa5','https://qa5.services.boeing.com'],
  ['lsft','https://lsft-tst-2.aviallinc.com:9002'],
  ['https://services.boeing.com','https://services.boeing.com'],
  ['localhost','https://qa1.services.boeing.com'],
  ['fleet-dev', 'https://fleet-dev.aviallinc.com'],
  ['fleet-val','https://fleet-val.aviallinc.com']
]);

//get the base url based on current host
export function getBaseUrl() {
  if(typeof window !== 'undefined'){
  const base =  window.location.href.toLowerCase();
  for (const [key, url] of urlMapper.entries()) {
    if (base.includes(key)) {
      return url;
    }
  }
  return ''; 
  }
}

export enum Environment { prod = 'production', qa1 = 'qa1', qa2 = 'qa2', stage = 'stage' };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
