import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { map } from 'rxjs';
import * as CryptoJS from 'crypto-js'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config:any;
  constructor(private http: HttpClient, private occEndpointService: OccEndpointsService) { }

  initializeConfig() {
    return this.fetchConfig().pipe(
      map((response: any) => {
      const encryptedText = response.config;
      const decryptedData = CryptoJS.enc.Base64.parse(encryptedText.substring(64)).toString(CryptoJS.enc.Utf8);
      this.config = JSON.parse(decryptedData);
       return this.config;
      })
    );
  }

  fetchConfig() {
    return this.http.get(this.occEndpointService.buildUrl("configuration"));
  }

  getConfig(code?: string) {
    return code ? this.config?.['myboeingfleet'][code] : this.config?.['myboeingfleet'];
    }
  
}
